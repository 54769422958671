import { getSoundcloudData } from '../utils/oembed.js'

class MusicItem extends window.HTMLElement {
  get mediaUrl() {
    return this.hasAttribute('media-url')
      ? this.getAttribute('media-url')
      : false
  }

  async getData() {
    this._root.classList.toggle('music-item--loading', true)

    const data = await getSoundcloudData(this.mediaUrl)
    const musicName = data.title.split('by')[0].trim()
    const artworkSrc = data.thumbnail_url

    // Overwrite values in markup
    this._nameEl.textContent = musicName
    this._artworkEl.src = artworkSrc
    this._artworkEl.setAttribute('alt', `Artwork for "${musicName}"`)
    this._recordEl.textContent = Array(15).fill(musicName).join(' - ')

    if (
      !this._root.hasAttribute('href') ||
      this._root.getAttribute('href') === ''
    ) {
      // No detail url has been set.
      // use the media url as href.
      this._root.setAttribute('href', this.mediaUrl)
      this._root.setAttribute('target', '_blank')
    }

    this._root.classList.toggle('music-item--loading', false)
  }

  connectedCallback() {
    this._root = this.firstElementChild
    this._artworkEl = this.querySelector('[artwork]')
    this._nameEl = this.querySelector('[music-name]')
    this._recordEl = this.querySelector('[record-name]')

    if (this.mediaUrl) {
      this.getData().then()
    }

    this.dataset.rendered = ''
  }
}

window.customElements.define('d-music-item', MusicItem)
